.logo {
    display: flex;
    align-items: center;

    gap: 0;
    border-radius: 37.37px;
    border: 0.84px solid var(--Blue-GR-BUTTON, #0462f9);

    &__badge {
        width: 37.76%;
        height: 100%;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateX(-1px);
        border-radius: 37.37px;
        background: var(
            --Blue-GR-BUTTON,
            linear-gradient(0deg, rgba(72, 160, 247, 0.7) 0%, rgba(72, 160, 247, 0.7) 100%),
            linear-gradient(93deg, #0462f9 32.99%, #101598 100%)
        );
    }
    &__text {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-left: -5px;
    }

    h1 {
        text-align: center;
        font-family: Unbounded;

        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}
