.ava {
    aspect-ratio: 1;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid;

    img {
        width: 100%;
        height: 100%;
    }
}

.no-matrix {
    @media (max-width: 1805px) {
        width: calc(var(--width) / 1.1) !important;
        height: calc(var(--height) / 1.1) !important;
    }

    @media (max-width: 1650px) {
        width: calc(var(--width) / 1.2) !important;
        height: calc(var(--height) / 1.2) !important;
    }

    @media (max-width: 1560px) {
        width: calc(var(--width) / 1.4) !important;
        height: calc(var(--height) / 1.4) !important;
    }

    @media (max-width: 1440px) {
        width: calc(var(--width) / 1.2) !important;
        height: calc(var(--height) / 1.2) !important;
    }

    @media (max-width: 1160px) {
        width: calc(var(--width) / 1.4) !important;
        height: calc(var(--height) / 1.4) !important;
    }
}
