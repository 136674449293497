.matrix-picture-circle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    scroll-snap-align: start;

    &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 2.2px;

        .elem {
            border-radius: 50%;
            border: 1px solid #3282e2;
            background: #d7dde7;
            aspect-ratio: 1;
        }

        .filled {
            background: linear-gradient(180deg, #0ff 0%, #007ff5 100%);

            border: 1px solid #51dfff;
        }

        .size-3 {
            width: 9px;
        }
        .size-2 {
            width: 10.5px;
        }
        .size-1 {
            width: 12px;
        }
    }

    &__low {
        flex-direction: row-reverse;
    }

    &__medium {
        gap: 10.5px;
        flex-direction: row-reverse;
    }
}
