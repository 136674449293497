.binar {
    width: 100%;
    &__select {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 24px;
        p {
            font-size: 14px;
            text-align: start;
        }
        .btns {
            width: 100%;
            background: var(--bg-color);

            display: flex;
            height: 44px;
            padding: 4px;
            justify-content: space-between;
            align-items: center;
            gap: 8px;

            border-radius: 12px;

            button {
                display: flex;
                padding: 8px 12px;
                justify-content: center;
                align-items: center;
                margin-top: 0;
                width: 100%;
                height: 100%;
                outline: none !important;

                border-radius: 8px;
                color: var(--t);
                text-align: center;

                font-family: Montserrat;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px; /* 142.857% */
                background: transparent;
                border: 1px solid transparent;

                -webkit-transition: border, backGround;
                -moz-transition: border, backGround;
                -o-transition: border, backGround;
                transition: border, backGround;
                transition-duration: 0.5s;
                opacity: 0.6;

                &.active {
                    background: var(--fee-btn);
                    border: 1px solid var(--fee-border);
                    opacity: 1;
                }
            }
        }
    }

    &__check {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 12px;
        margin-top: 16px;
        padding: 20px 24px;
        border-radius: 12px;
        background: var(--op);
        border: 1px solid var(--line);

        h2 {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            text-align: center;
            font-family: sans-serif !important;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;

            span {
                font-family: sans-serif;
                font-size: 16px;
                color: var(--t);
                opacity: 0.8;
            }
        }
    }

    .btns {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        button {
            // margin-top: 24px;
            max-width: unset;
        }
    }

    .down {
        button {
            margin-top: 24px;
        }
    }
}

.areas {
    // margin-bottom: 84px;
    min-height: 100%;
    display: flex;
    gap: 40px;
    align-items: start !important;
    flex-direction: row !important;
    padding-bottom: 16px;

    min-height: 100%;
    b .line {
        height: 1px;
        background-color: var(--line);
        margin: 24px 0;
        width: 100%;
        right: 0;
    }

    &__user {
        max-width: 360px;
        min-width: 360px;
        min-height: calc(100vh - 32px);
        clear: both;
        // max-height: calc(100% + 1px);
        max-height: calc(100vh - 32px);
        background: var(--secondary-bg-color);
        position: relative;
        z-index: 99;

        border-radius: 30px;
        display: flex;
        transition: transform 0.4s ease;

        @media (max-width: 1440px) {
            transform: translateX(-150px);

            &.open {
                transform: translateX(200px);
                box-shadow: rgba(0, 0, 0, 0.48) 6px 2px 16px 0px, rgba(0, 0, 0, 0.8) -6px -2px 16px 0px;
            }
        }

        .close-arr {
            position: absolute;
            right: -30px;
            top: 50%;
            transform: rotate(90deg);
            width: 30px;
            height: 30px;
            display: none;

            &.open {
                // display: block;
            }
        }

        .account-profile {
            overflow: scroll;
            padding: 16px;
            h2 {
                display: none;
            }
        }

        .load-user {
            display: flex;
            flex-direction: column;
            width: 100%;
            justify-content: start;
            padding: 16px;
            &__info {
                width: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: start;
                gap: 10px;
                height: 232.64px;
                padding: 16px;
                border-radius: 16px;
            }
        }
    }

    .area {
        &__main {
            min-height: calc(100vh - 32px);
            // height: 1000%;

            min-width: calc(100% - 400px);
            background: var(--secondary-bg-color);
            padding: 16px;
            border-radius: 24px;
            anchor-name: --main;
            display: flex;
            align-items: center;
            gap: 72px;
            overflow-x: scroll;
            flex-direction: column;
            position: relative;
            transition: transform 0.4s ease;

            .lcl {
                position: absolute;
                top: 120px;
                right: 30px;
                z-index: 999999;
            }

            .circle-big {
                @media (max-width: 1805px) {
                    width: calc(80px / 1.1) !important;
                    height: calc(80px / 1.1) !important;
                }

                @media (max-width: 1650px) {
                    width: calc(80px / 1.2) !important;
                    height: calc(80px / 1.2) !important;
                }
                @media (max-width: 1560px) {
                    width: calc(80px / 1.4) !important;
                    height: calc(80px / 1.4) !important;
                }

                @media (max-width: 1440px) {
                    width: calc(80px / 1.2) !important;
                    height: calc(80px / 1.2) !important;
                }

                @media (max-width: 1160px) {
                    width: calc(80px / 1.4) !important;
                    height: calc(80px / 1.4) !important;
                }
            }

            .circle-small {
                @media (max-width: 1805px) {
                    width: calc(60px / 1.4) !important;
                    height: calc(60px / 1.4) !important;
                }

                @media (max-width: 1650px) {
                    width: calc(60px / 1.2) !important;
                    height: calc(60px / 1.2) !important;
                }
                @media (max-width: 1560px) {
                    width: calc(60px / 1.4) !important;
                    height: calc(60px / 1.4) !important;
                }
                @media (max-width: 1440px) {
                    width: calc(60px / 1.2) !important;
                    height: calc(60px / 1.2) !important;
                }

                @media (max-width: 1160px) {
                    width: calc(60px / 1.4) !important;
                    height: calc(60px / 1.4) !important;
                }
            }

            .no-active {
                width: 100%;
                height: calc(100vh - 100px);
                display: flex;
                flex-direction: column;
                justify-content: start;

                h1 {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                }

                .circle-group {
                    position: relative;
                    z-index: 2;
                    display: flex;
                    height: 100%;
                    justify-content: center;
                    gap: 24px;
                    align-items: center;

                    .skelet {
                        position: absolute;
                        width: 82%;
                        top: 45px;
                    }

                    .tree-node {
                        display: flex;
                        justify-content: center;
                        flex-direction: row;
                        gap: 26.6px;
                    }
                    .tree-col {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 72px;
                    }
                    .horizontal {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                    }
                    @keyframes circle {
                        from {
                            opacity: 0;
                            transform: scale(0);
                        }
                        to {
                            opacity: 1;
                            transform: scale(1);
                        }
                    }
                    &__circle1 {
                        border-width: 2px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        cursor: pointer;
                        animation: circle 0.2s ease-in;
                        position: relative;
                        display: flex;

                        .cirlce-1,
                        .cirlce-2 {
                            position: absolute;
                            top: 6px;
                            // right: 0;
                            width: 60px;
                            height: 60px;
                            border-radius: 50%;
                            overflow: hidden;
                            border: 2px solid var(--border-team-item);
                            background: var(--ln-bg-b);
                        }

                        .cirlce-1 {
                            z-index: -1;
                        }

                        .cirlce-2 {
                            top: 12px;
                            z-index: -2;
                        }

                        &.small {
                            width: 60px;
                            height: 60px;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                        }

                        .login-or-id {
                            position: absolute;
                            z-index: 2;
                            bottom: -9px;
                            border-radius: 4px;
                            padding: 4px;
                            background: var(--ln-bg);
                            max-width: 150px;
                            overflow: hidden;
                            h1 {
                                font-size: 14px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                font-weight: 400 !important;
                            }
                        }

                        .arrow {
                            width: 18px;
                            height: 18px;
                            position: absolute;
                            bottom: -9px;
                            z-index: 3;
                        }
                    }
                }
            }

            &--header {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .zag {
                    width: 228px;
                    height: 76px;
                }

                .tabs {
                    width: 228px;
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;
                    padding: 16px;
                    border-radius: 16px;
                    background: var(--ln-bg);
                    gap: 4px;

                    .btns {
                        width: 100%;
                        background: var(--bg-color);

                        display: flex;
                        height: 44px;
                        padding: 4px;
                        justify-content: space-between;
                        align-items: center;
                        gap: 8px;

                        border-radius: 12px;

                        button {
                            cursor: pointer;
                            display: flex;
                            padding: 8px 12px;
                            justify-content: center;
                            align-items: center;
                            margin-top: 0;
                            width: 100%;
                            height: 100%;
                            outline: none !important;

                            border-radius: 8px;
                            color: var(--t);
                            text-align: center;

                            font-family: Montserrat;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 20px; /* 142.857% */
                            background: transparent;
                            border: 1px solid transparent;

                            -webkit-transition: border, backGround;
                            -moz-transition: border, backGround;
                            -o-transition: border, backGround;
                            transition: border, backGround;
                            transition-duration: 0.5s;
                            opacity: 0.6;

                            &.active {
                                background: var(--fee-btn);
                                border: 1px solid var(--fee-border);
                                opacity: 1;
                            }
                        }
                    }
                }
                .stat {
                    display: flex;
                    align-items: center;
                    gap: 60px;
                    @media (max-width: 1805px) {
                        gap: 20px;
                    }
                    &__item {
                        width: 228px;
                        overflow: hidden;
                        display: flex;
                        flex-direction: column;
                        padding: 16px;
                        border-radius: 16px;
                        background: var(--ln-bg);
                        gap: 4px;
                        @media (max-width: 1805px) {
                            width: 150px;
                        }

                        p {
                            font-size: 13px;
                            color: #8590ac !important;
                            text-align: center;
                        }
                        h1 {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-size: 17px;
                            color: #fff !important;
                            text-align: center;

                            // @media (max-width: 1805px) {
                            //     font-size: 15px;
                            // }
                        }
                    }
                }

                .sign-out {
                    width: 228px;
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;
                    padding: 16px;
                    border-radius: 16px;
                    background: var(--ln-bg);
                    gap: 4px;

                    button {
                        cursor: pointer;
                    }
                }
            }

            .version {
                position: absolute;
                bottom: 16px;
                color: #8590ac;
                font-family: Unbounded;
                font-size: 12px;
                font-weight: 500;
            }

            .load {
                position-anchor: --main;
                top: calc(anchor(top) + 10%);
            }

            .border-circle {
                // margin-top: 94px;
                min-height: 84px;
                min-width: 84px;
                display: flex;
                justify-content: center;
                @media (max-width: 1805px) {
                    min-height: calc(84px / 1.4);
                    min-width: calc(84px / 1.4);
                }
                position: relative;
                z-index: 1;

                .up {
                    border-radius: 16px;
                    padding: 7px 10px;
                    height: 80px;
                    background: var(--ln-bg-b);
                    border: 1px solid var(--b-br);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    position: absolute;
                    top: -32px;
                    z-index: -1;
                    cursor: pointer;

                    svg {
                        path {
                            stroke: var(--b-t);
                        }
                    }
                    p {
                        margin: 0;
                        color: var(--b-t) !important;
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 14px; /* 140% */
                    }
                }

                .login-or-id {
                    position: absolute;
                    z-index: 2;
                    bottom: -9px;
                    border-radius: 4px;
                    padding: 4px;
                    background: var(--ln-bg);

                    // max-width: calc(100% + 5px);
                    overflow: hidden;
                    h1 {
                        font-size: 14px;
                        font-weight: 400 !important;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }

            .circle-group {
                position: relative;
                z-index: 2;
                display: flex;
                height: 100%;
                justify-content: center;
                gap: 24px;
                align-items: center;

                .skelet {
                    position: absolute;
                    width: 82%;
                    top: 45px;
                }

                .tree-node {
                    display: flex;
                    justify-content: center;
                    flex-direction: row;
                    gap: 26.6px;
                    @media (max-width: 1805px) {
                        gap: 20px;
                    }
                }
                .tree-col {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 72px;
                }
                .horizontal {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }
                @keyframes circle {
                    from {
                        opacity: 0;
                        transform: scale(0);
                    }
                    to {
                        opacity: 1;
                        transform: scale(1);
                    }
                }
                &__circle {
                    width: 80px;
                    height: 80px;
                    border-width: 2px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    background: var(--ln-bg);
                    cursor: pointer;
                    animation: circle 0.2s ease-in;
                    position: relative;
                    display: flex;
                    @media (max-width: 1805px) {
                        width: calc(80px / 1.1) !important;
                        height: calc(80px / 1.1) !important;
                    }

                    @media (max-width: 1650px) {
                        width: calc(80px / 1.2) !important;
                        height: calc(80px / 1.2) !important;
                    }

                    @media (max-width: 1560px) {
                        width: calc(80px / 1.4) !important;
                        height: calc(80px / 1.4) !important;
                    }

                    @media (max-width: 1440px) {
                        width: calc(80px / 1.2) !important;
                        height: calc(80px / 1.2) !important;
                    }

                    @media (max-width: 1160px) {
                        width: calc(80px / 1.4) !important;
                        height: calc(80px / 1.4) !important;
                    }

                    .cirlce-1,
                    .cirlce-2 {
                        position: absolute;
                        top: 6px;
                        // right: 0;
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        overflow: hidden;
                        border: 2px solid var(--border-team-item);
                        background: var(--ln-bg-b);

                        @media (max-width: 1805px) {
                            width: calc(60px / 1.1) !important;
                            height: calc(60px / 1.1) !important;
                        }

                        @media (max-width: 1650px) {
                            width: calc(60px / 1.2) !important;
                            height: calc(60px / 1.2) !important;
                        }

                        @media (max-width: 1560px) {
                            width: calc(60px / 1.4) !important;
                            height: calc(60px / 1.4) !important;
                        }

                        @media (max-width: 1440px) {
                            width: calc(60px / 1.2) !important;
                            height: calc(60px / 1.2) !important;
                        }

                        @media (max-width: 1160px) {
                            width: calc(60px / 1.4) !important;
                            height: calc(60px / 1.4) !important;
                        }
                    }

                    .cirlce-1 {
                        z-index: -1;
                    }

                    .cirlce-2 {
                        top: 12px;
                        z-index: -2;
                    }

                    &.small {
                        width: 60px;
                        height: 60px;

                        @media (max-width: 1805px) {
                            width: calc(60px / 1.1) !important;
                            height: calc(60px / 1.1) !important;
                        }

                        @media (max-width: 1650px) {
                            width: calc(60px / 1.2) !important;
                            height: calc(60px / 1.2) !important;
                        }

                        @media (max-width: 1560px) {
                            width: calc(60px / 1.4) !important;
                            height: calc(60px / 1.4) !important;
                        }

                        @media (max-width: 1440px) {
                            width: calc(60px / 1.2) !important;
                            height: calc(60px / 1.2) !important;
                        }

                        @media (max-width: 1160px) {
                            width: calc(60px / 1.4) !important;
                            height: calc(60px / 1.4) !important;
                        }
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                    }

                    .login-or-id {
                        position: absolute;
                        z-index: 2;
                        bottom: -9px;
                        border-radius: 4px;
                        padding: 4px;
                        background: var(--ln-bg);
                        max-width: 150px;
                        overflow: hidden;
                        h1 {
                            font-size: 14px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-weight: 400 !important;

                            @media (max-width: 1805px) {
                                font-size: 10px;
                            }

                            @media (max-width: 1560px) {
                                font-size: 8px;
                            }
                        }
                    }

                    .arrow {
                        width: 18px;
                        height: 18px;
                        position: absolute;
                        bottom: -9px;
                        z-index: 3;
                    }
                }
            }
        }

        @keyframes circle {
            from {
                opacity: 0;
                transform: scale(0);
            }
            to {
                opacity: 1;
                transform: scale(1);
            }
        }
    }

    .exist-binary {
        @media (max-width: 1440px) {
            transform: translateX(-180px);
            min-width: 100%;
        }
    }

    .no-binary {
        width: 100%;
    }
}
