.account-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 16px;
    overflow: hidden;

    .about-meta {
        margin-top: 0px;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 8px;

        p {
            color: #8590ac;
            font-size: 14px;
            display: flex;
            align-items: center;
            gap: 4px;
            cursor: pointer;

            span {
                color: var(--blue-p);
            }

            svg {
                path {
                    fill: var(--blue-p);
                }
            }
        }
    }

    .line {
        height: 1px;
        background-color: var(--line);
        margin-bottom: 8px;
        width: 100%;
        bottom: -32px;
        right: 0;
    }

    h2 {
        text-align: center;
        font-family: sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 114.286% */
        margin-top: 4px;
    }

    .conteiner {
        background: var(--ln-bg);
        padding: 16px;
        border-radius: 16px;
    }

    &__info {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 8px;

        &--data {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            width: 100%;

            h1 {
                font-size: 18px;
                max-width: 300px;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            p {
                color: #b1b3c0;
                cursor: pointer;
                text-align: center;
                font-family: sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px; /* 114.286% */
            }
        }

        &--links {
            display: flex;
            flex-direction: column;
            gap: 8px;
            align-items: center;
            button {
                margin-top: 16px;
                cursor: pointer;
            }
            span {
                display: flex;
                align-items: center;
                gap: 4px;
                cursor: pointer;
                overflow: hidden;
                color: var(--accent);
                text-align: center;
                text-overflow: ellipsis;
                font-family: sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px; /* 114.286% */
                text-decoration-line: underline;
            }
        }
    }

    .block {
        display: flex;

        padding: 16px;
        flex-direction: column;
        align-items: flex;
        justify-content: center;
        gap: 12px;
        width: 100%;
        height: 72px;
        border-radius: 16px;
        background: var(--ln-bg);

        .not {
            width: 100%;
            text-align: center;
        }
    }

    .partners {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .line {
        min-height: 1px;
    }

    .places {
        display: flex;
        justify-content: flex-start;
        gap: 8px;
        min-height: 101px;
        overflow: hidden;
        overflow-x: scroll;
        margin: 0 -16px;
        padding: 0 16px;
        width: calc(100% + 32px);
        scroll-snap-type: x mandatory;
        scroll-padding-left: 32px;
    }
}

.modal-matrix {
    position: absolute;
    position-anchor: --matrix;
    // width: 100vw;
    // height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 99999;
    overflow: hidden;

    &__backdrop {
        top: 0;
        left: 0;
        position: absolute;
        width: 100vw;
        height: 100vh;
        z-index: 100000;

        backdrop-filter: blur(4px);
    }

    &__content {
        width: 400px;
        // height: 700px;
        position: relative;
        z-index: 100001;

        &--close {
            position: absolute;
            cursor: pointer;
            top: -20px;
            right: -20px;
        }
    }
}

.areas1 {
    width: 350px;
    margin-top: 50px;
    // border: 1px solid white;
    .area1 {
        width: 100%;
        padding: 8px;
        border-radius: 16px;
        margin-top: 16px;
        background: var(--secondary-bg-color);

        &__main {
            // position: relative;
            anchor-name: --main;
            display: flex;
            align-items: center;
            gap: 17px;
            justify-content: center;
            min-height: 188px;

            .load {
                // position: absolute;
                position-anchor: --main;
                top: calc(anchor(top) + 10%);
            }

            .circle-group {
                position: relative;
                z-index: 2;
                display: flex;
                height: 100%;
                justify-content: center;
                gap: 24px;
                align-items: center;

                .skelet {
                    position: absolute;
                    width: 82%;
                    top: 45px;
                }

                .tree-node {
                    display: flex;
                    justify-content: center;
                    flex-direction: row;
                    gap: 24px;
                }
                .tree-col {
                    display: flex;
                    flex-direction: column-reverse;
                    align-items: center;
                    gap: 24px;
                }
                .horizontal {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }
                @keyframes circle {
                    from {
                        opacity: 0;
                        transform: scale(0);
                    }
                    to {
                        opacity: 1;
                        transform: scale(1);
                    }
                }
                &__circle {
                    width: 64px;
                    height: 64px;
                    border-width: 2px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    // border: 2px solid var(--border);
                    background: var(--ln-bg);
                    animation: circle 0.2s ease-in;
                    position: relative;
                    z-index: 3;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        cursor: pointer;
                    }
                }
            }
        }
        @keyframes circle {
            from {
                opacity: 0;
                transform: scale(0);
            }
            to {
                opacity: 1;
                transform: scale(1);
            }
        }
        .partner {
            position: relative;
            z-index: 2;
            width: 100%;
            height: 124px;
            border-radius: 16px;
            // animation: circle 0.2s ease-in;
            background-image: linear-gradient(125deg, rgba(255, 255, 255, 0.1) 25%, rgba(255, 255, 255, 0) 69%, rgba(255, 255, 255, 0.1) 94%);
            padding: 1px;

            margin-top: 16px;

            .inner {
                width: 100%;
                height: 100%;

                background: var(--ln-bg2);
                border-radius: 16px;
                padding: 8px;

                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                .avatar {
                    display: flex;
                    align-items: center;
                    gap: 24px;
                    .not-back {
                        width: 36px;
                        height: 36px;
                    }
                    .icon {
                        width: 36px;
                        height: 36px;
                        cursor: pointer;
                        user-select: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding: 0;
                        border-radius: 8px;
                        background: var(--secondary-bg-color);

                        svg {
                            path {
                                fill: var(--svg-blue);
                            }
                        }
                    }

                    .border-circle {
                        // background: #0462f9;
                        // padding: 2px;
                        border-radius: 50%;
                    }

                    .circle {
                        min-width: 84px;
                        min-height: 84px;
                        flex-grow: 0;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: var(--ln-bg);
                        // background-image: linear-gradient(to bottom, #181e2e, #181e2e),
                        //   linear-gradient(147deg, #026bfc 14%, #0845cc 100%);
                        // background-origin: border-box;
                        // background-clip: content-box, border-box;

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                        }
                    }
                }
                h3 {
                    margin-top: 8px;
                    font-size: 14px;
                    font-weight: 500;
                    color: var(--text-color) !important;
                    line-height: 1.43;
                    min-height: 20px;
                }
            }
        }
        &__pagination::-webkit-scrollbar {
            display: none;
        }
        &__pagination {
            display: flex;
            overflow-x: scroll;
            align-items: center;
            gap: 20px;
            width: 100%;
            justify-content: flex-start;
            padding: 8px 8px 4px 8px;
            height: 36px;
            min-height: 36px;
            border-top: solid 1px rgba(76, 140, 255, 0.28);
            margin-top: 24px;
            overflow-y: hidden;

            p {
                cursor: pointer;
            }

            .active {
                display: flex;
                justify-content: center;
                align-items: center;
                background: var(--Blue-but-GR, linear-gradient(252deg, #0c8ce9 11.89%, #20436c 88.11%));
                border-radius: 20px;
                padding: 8px;
                min-width: 24px;
                height: 24px;
                color: #fff !important;
                margin: 0 -8px;
                overflow-y: hidden;
            }
        }
    }

    .team-binar-item {
        height: 107px;
        min-width: 60px;
        width: fit-content;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        &__avatar {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            overflow: hidden;
            border: 2px solid #4d9ffc;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 3;
            background: var(--ln-bg);
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .arrow-binary {
            position: absolute;
            z-index: 10;
            top: 49px;
        }

        &__cirlce-1,
        &__cirlce-2 {
            position: absolute;

            top: 6px;
            right: 0;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            overflow: hidden;
            border: 2px solid var(--border-team-item);
            background: var(--ln-bg-b);
        }

        &__cirlce-1 {
            z-index: 2;
        }

        &__cirlce-2 {
            top: 12px;
        }

        &__partners {
            position: absolute;
            top: 85px;
            display: flex;
            align-items: center;
            gap: 4px;
            padding: 4px 6px;
            background: var(--ln-bg);
            border: 1px solid var(--ln-bg-b);
            border-radius: 8px;
            p {
                margin: 0;
                color: var(--Text-Blue-menu, #3da2ff);

                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 14px; /* 116.667% */
            }
        }
    }
}
