.user-profile {
    width: 100%;
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    align-items: center;
    justify-content: start;
    gap: 16px;
    align-self: stretch;

    border-radius: 16px;

    .short-profile__info {
        width: 100%;
        border-radius: 16px;
        background: var(--ln-bg);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 16px;

        gap: 8px;
        h1 {
            font-size: 18px;
        }
        p {
            color: #8590ac;
            font-size: 14px;
        }
        span {
            color: var(--blue-p);
            svg {
                path {
                    fill: var(--blue-p);
                }
            }
        }

        button {
            margin-top: 16px;
            width: 170px !important;
        }
    }
}

.about-meta {
    margin-top: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;

    p {
        color: #8590ac !important;
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 4px;

        span {
            color: var(--blue-p);
        }

        svg {
            path {
                fill: var(--blue-p);
            }
        }
    }
}
.block {
    width: 100%;
    display: flex;

    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    border-radius: 16px;
    background: var(--ln-bg);
}
.ind {
    justify-content: center;
    flex-direction: row !important;
    // margin: 16px 0 0 0;
}
.line {
    height: 1px;
    background-color: var(--line);
    margin-bottom: 8px;
    min-width: 100% !important;
    margin: 24px 0;
    path {
        min-width: 100% !important;
    }
}
ut-meta {
    margin-top: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;

    p {
        color: #8590ac !important;
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 4px;

        span {
            color: var(--blue-p);
        }

        svg {
            path {
                fill: var(--blue-p);
            }
        }
    }
}
