.user-banner {
    display: flex;
    cursor: pointer;
    min-height: 72px;
    padding: 16px;
    align-items: flex-start;
    gap: 12px;
    width: 100%;
    border-radius: 16px;
    background: var(--ln-bg);

    overflow: hidden;
    flex-direction: row;
    align-items: center;

    &:active {
        transform: scale(0.98);
        transition: scale 0.3s;
    }

    h3 {
        overflow: hidden;

        text-overflow: ellipsis;
        font-family: sans-serif !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    p {
        overflow: hidden;
        color: var(--Blue-text-2, #89b1ff);
        text-overflow: ellipsis;
        font-family: sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;

        svg {
            margin: 0 8px;
        }
    }
}
