.loading-app {
    width: 100vw;
    height: 100vh;
    bottom: 0;
    left: 0;
    position: absolute;
    background: var(--ln-bg);
    z-index: 1000;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 72px;

    &__head {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 16px;

        &--title {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            h2 {
                width: fit-content;
                font-size: 14.526px;
                font-style: normal;
                font-weight: 500;
            }
            h1 {
                width: fit-content;
                font-size: 19.368px;
                font-style: normal;
                font-weight: 500;
            }
        }
    }
    &__progress {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        p {
            color: var(--transfer-input) !important;
            text-align: center;
            font-family: sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 166.667% */
            opacity: 0.6;
        }

        &--bar {
            svg {
                display: flex;
                justify-content: center;

                #main {
                    fill: var(--progress);
                }

                rect {
                    transition: width 0.3s ease;
                }
            }
        }
    }
}
