:root {
    --bg-matrix-picture: linear-gradient(223deg, #2e85f5 37.63%, #3269d1 55.64%);
    --secondary-bg-matrix-picture: linear-gradient(129deg, #3c67b2 27.44%, #344c6f 71.38%);
    --b-br: #4d9ffc;
    --b-t: #a1d8ff;
    --b-bg: #333650;
    --border-team-item: #244870;
    --br-bin: rgba(0, 117, 255, 0.1);
    --gray-blue-card: linear-gradient(0.25turn, rgba(56, 56, 57, 0.3), rgb(36, 36, 36), rgba(21, 32, 49, 0.3), rgba(0, 44, 156, 0.3));
    --op: rgba(0, 0, 0, 0.25);
    --blue-p: #89b1ff;
    --accent: #1d77ff;
    --progress: #d9d9d9;
    --max-width: 470px;
    --fee-border: #0461ef;
    --fee-btn: #181e2e;
    --fee-bg: rgba(0, 0, 0, 0.2);
    --qr-bg: #fff;
    --arrow-modal: #506690;
    --transfer-label: #b1b3c0;
    --transfer-input: #fff;
    --modal-bg: linear-gradient(180deg, rgba(56, 56, 57, 0.3) -52.42%, #242424 121.77%), linear-gradient(93deg, #152031 32.99%, #002c9c 100%);
    --modal-close-button: #1d1e20;
    --modal-text: #fff;
    --wallet-icon: linear-gradient(0deg, rgba(107, 149, 255, 0.2) 0%, rgba(107, 149, 255, 0.2) 100%), rgba(0, 117, 255, 0.1);
    --bg-color: #000a14;
    --active-tab: #34ab51;
    --secondary-bg-color: #131315;
    --border-blue: #6b95ff;
    --blue-text: #89b1ff;
    --Green-GR: linear-gradient(rgba(52, 171, 81, 1), rgba(44, 81, 53, 1));
    --spinner: #fff;
    --light-border: transparent;
    --ln-bg: linear-gradient(180deg, rgba(56, 56, 57, 0.3) -52.42%, #242424 121.77%), linear-gradient(93deg, #152031 32.99%, #002c9c 100%);
    --ln-bg-b: linear-gradient(180deg, rgba(56, 56, 57, 0.3) -52.42%, #242424 121.77%), linear-gradient(93deg, #152031 32.99%, #002c9c 100%);
    --ln-bg2: linear-gradient(180deg, rgba(56, 56, 57, 0.3) -52.42%, #242424 121.77%), linear-gradient(93deg, #152031 32.99%, #002c9c 100%);
    --ln-bg3: linear-gradient(180deg, rgba(56, 56, 57, 0) -52.42%, #242424 121.77%), #383839;
    --dot: linear-gradient(180deg, rgba(56, 56, 57, 0.3) -52.42%, #242424 121.77%), linear-gradient(93deg, #152031 32.99%, #002c9c 100%);
    --nav-bg: linear-gradient(0deg, rgba(0, 117, 255, 0.5) 0%, rgba(0, 117, 255, 0.5) 100%), linear-gradient(93deg, #0462f9 32.99%, #101598 100%);
    --text-color: #89b1ff;
    --pre-input: #1d1e20;
    --input: #131315;
    --copy: linear-gradient(0deg, rgba(0, 117, 255, 0.5) 0%, rgba(0, 117, 255, 0.5) 100%), linear-gradient(93deg, #0462f9 32.99%, #101598 100%);
    --copy-svg: #89b1ff;
    --border: #0462f9;
    --svg-blue: #89b1ff;
    --t: #fff;
    --line: #506690;
    --tab: linear-gradient(180deg, rgba(56, 56, 57, 0.3) -52.42%, #242424 121.77%), linear-gradient(93deg, #152031 32.99%, #002c9c 100%);
    --gr-b: linear-gradient(0deg, rgba(0, 117, 255, 0.5) 0%, rgba(0, 117, 255, 0.5) 100%), linear-gradient(93deg, #0462f9 32.99%, #101598 100%);
    --gr-g: linear-gradient(252deg, #34ab51 11.89%, #2c5135 88.11%);
    --gr-s: linear-gradient(252deg, #0c8ce9 11.89%, #20436c 88.11%);
    --gr-r: linear-gradient(252deg, #e7515a 11.89%, #6f2c38 88.11%);
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #ffffff;
        font-family: Unbounded;
    }
    p {
        color: #89b1ff;
        font-family: sans-serif;
    }
}
