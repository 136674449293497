.modal {
    width: 100vw;
    max-width: var(--max-width);
    min-height: calc(100vh - 32px);
    max-height: calc(100vh - 32px);
    position: absolute;
    max-width: 362px;

    top: 0;
    left: 0;
    overflow: hidden;
    display: flex;
    align-items: end;
    justify-content: center;
    flex-direction: column;
    z-index: 1001;
    backdrop-filter: brightness(0.8);
    border-radius: 20px;

    .out {
        width: 100%;
        height: 100%;
        display: none;
    }

    @keyframes dialog {
        from {
            transform: translateY(100%);
        }
        to {
            transform: translateY(0);
        }
    }

    @keyframes close {
        from {
            transform: translateY(0);
        }
        to {
            transform: translateY(100%);
        }
    }

    #close {
        animation: close 0.3s forwards;
    }

    &__dialog {
        width: 100%;
        transform: translateY(100%);
        border-radius: 16px 16px 0 0;
        // min-height: 420px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;
        position: relative;

        animation: dialog 0.3s forwards;

        background: var(--modal-bg);

        .children {
            // overflow-x: hidden;
            overflow-y: auto;
        }

        &--close-btn {
            cursor: pointer;
            background: none;
            position: absolute;
            top: 5px;
            left: 5px;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background: var(--modal-close-button);
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                path {
                    fill: var(--arrow-modal);
                }
            }
        }

        &--content {
            overflow-y: scroll;
            // padding: 16px 0;
            min-height: calc(100vh - 32px);
            max-height: calc(100vh - 32px);
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: start;
            gap: 8px;

            background: var(--secondary-bg-color);

            button {
                margin-top: 24px;
            }
        }
    }
}

.profile-modal {
    .modal__dialog {
        height: 100%;
        border-radius: 0;
        justify-content: start;
        background: var(--bg-color);
    }
    // overflow-y: scroll;
}
