@font-face {
    font-family: SF;
    src: url('./fonts/SF/SFPRODISPLAYREGULAR.OTF') format('opentype');
    font-weight: 400;
}

@font-face {
    font-family: SF;
    src: url('./fonts/SF/SFPRODISPLAYBOLD.OTF') format('opentype');
    font-weight: 800;
}

@font-face {
    font-family: SF;
    src: url('./fonts/SF/SFPRODISPLAYMEDIUM.OTF') format('opentype');
    font-weight: 600;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition: color 0.2s ease;
    /* max-width: var(--max-width); */
    /* transition: background, background-color 2s ease-in-out; */
}

#root {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--bg-color);
    width: 100vw;

    height: 100vh;
    anchor-name: --matrix;

    .lcla {
        position: absolute;
        top: 50px;
        right: 50px;
    }
}

html {
    min-height: 100%;
    overflow: hidden;
    anchor-name: --body;

    /* max-width: var(--max-width); */
}

.qr {
    padding: 24px 32px;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 16px;
    background: var(--Text-1, #fff);
    max-width: 248px;

    .ant-qrcode {
        width: 184px !important;
        height: auto !important;
    }
}

.qr-text {
    color: var(--BG-1, #060818) !important;
    text-align: center;
    text-wrap: wrap;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    opacity: 0.6;
    max-width: 100%;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.observe-item {
    width: 100%;
    /* height: 40px; */
    display: flex;
    align-items: center;
    justify-content: center;
}

#layout {
    width: 100%;
    min-width: 100%;
    // max-width: 400px;
    padding: 16px 16px 16px 16px;
    background-color: var(--bg-color);
    max-width: var(--max-width);
    min-height: 100%;
}

.error {
    width: 100%;
    margin: 40px 0 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
        /* text-align: center; */
        margin: 10px 0;
        color: red !important;

        display: flex;
        align-items: center;

        button {
            margin: 0;
            background: transparent;
            border: none;
            outline: none;
        }
    }

    h4 {
        font-size: 12px;
        text-align: center;
        opacity: 0.5;
    }
}

body {
    background-color: var(--bg-color);
    // height: 100vh;
    overflow: auto;
    /* overflow-y: auto; */
    min-height: 100%;
}

::-webkit-scrollbar {
    display: none;
}

#section {
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100%;
}

h1 {
    font-weight: 500;
    font-size: 20px;
}

.spinner {
    width: 32px;
    height: 32px;
    border: 1px solid var(--spinner);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    display: flex;

    &.small {
        width: 20px;
        height: 20px;
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.sign-in {
    background: var(--ln-bg);
    padding: 32px 36px;
    display: flex;
    flex-direction: column;

    align-items: center;
    border-radius: 30px;

    .logo {
        margin-bottom: 38px;
    }

    p {
        margin-bottom: 8px;
        color: rgba(255, 255, 255, 0.6) !important;
        font-size: 16px;
        line-height: 20px;
    }

    .nots {
        width: 248px;
        text-align: start;

        span {
            color: #fff !important;
            font-weight: 900;
        }
    }

    .value {
        padding: 24px 32px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 16px;
        min-width: 248px;
        min-height: 232px;

        &.loading {
            transform: scale(1.05);
            transition: transform 1s ease;
        }

        transition: transform 1s ease;

        .ant-qrcode {
            width: 184px !important;
            height: 184px !important;
            min-width: 184px !important;
            min-height: 184px !important;
            padding: 0 !important;
        }
    }
}
