.matrix-picture {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70px;
    min-width: 70px;
    height: 101px;
    border-radius: 16px;
    cursor: pointer;

    &.disabled {
        opacity: 0.4;

        .elem.size-1 {
            border: 1px solid #3282e2;
            background: #d7dde7;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 16px 16px 0 0;
        border: 1px solid #4d9ffc;
        border-bottom: none;
        width: 100%;
        padding: 8px 8px 0 8px;
        height: 100%;
        background: var(--bg-matrix-picture);
        h1 {
            color: #fff !important;
            font-size: 24px;
            font-weight: 700;
            line-height: 22px; /* 91.667% */
        }

        p {
            overflow: hidden;
            color: #c1d6f7 !important;
            text-overflow: ellipsis;
            font-family: Unbounded;
            font-size: 9px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px; /* 155.556% */

            display: flex;
            // align-items: center;
            gap: 5px;
        }
    }

    &__picture {
        display: flex;

        align-items: center;
        justify-content: center;
        border-radius: 0 0 16px 16px;
        border: 1px solid #4d9ffc;
        background: var(--secondary-bg-matrix-picture);
        width: 100%;

        height: 100%;
    }
}
