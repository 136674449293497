.locale-container {
    display: flex;
    flex-direction: column;
    // justify-content: end;
    .icon {
        // width: 20px;
        // height: 20px;
        // overflow: hidden;
        cursor: pointer;

        display: flex;
        justify-content: end;
    }

    @keyframes lcl {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    .locale {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
        background: var(--ln-bg);
        padding: 16px;
        border-radius: 16px;
        animation: lcl 0.4s ease;
        min-width: 240px;

        h1 {
            margin: 8px 0 8px 0;
            text-align: center;
        }

        &__item {
            width: 100%;
            cursor: pointer;
            display: flex;
            padding: 12px 16px;
            align-items: center;
            gap: 8px;
            justify-content: space-between;

            &--data {
                display: flex;
                align-items: center;
                gap: 8px;
                h2 {
                    font-family: sans-serif !important;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%; /* 16.8px */
                    letter-spacing: 0.14px;
                }
            }

            svg {
                fill: var(--t);
                path {
                    fill: var(--t);
                }
            }
        }
    }
}
