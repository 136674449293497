.button {
    width: 100%;
    display: flex;
    min-height: 40px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;

    border-radius: 8px;

    font-family: sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 114.286% */

    outline: none;
    border: none;
}
